import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
	// Default to using the first account if no account is active on page load
	if (
		!msalInstance.getActiveAccount() &&
		msalInstance.getAllAccounts().length > 0
	) {
		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
	}

	// This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents();

	msalInstance.addEventCallback((event) => {
		if (
			event.eventType === EventType.LOGIN_SUCCESS &&
			event.payload.account
		) {
			const account = event.payload.account;
			msalInstance.setActiveAccount(account);
		}
	});
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App pca={msalInstance} />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import PropTypes from "prop-types";
import {
	Theme,
	Drawer,
	Menu,
	Dropdown,
	Card,
	Button,
	Avatar,
} from "react-daisyui";
import { Link, useLocation } from "react-router-dom";
import msGraphApi from "../utils/msGraphApi";
import { CiLogout, CiUser } from "react-icons/ci";
import { useMsal } from "@azure/msal-react";

const AppLayout = (props) => {
	const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

	return (
		<Theme dataTheme="night">
			<div className="text-foreground w-full flex flex-col min-h-screen bg-base-100">
				<header className="w-full sticky z-50 top-0">
					<div className="navbar bg-primary bg-opacity-70 backdrop-blur-lg">
						<div className="navbar-start">
							<button
								tabindex="0"
								className="btn btn-ghost md:hidden"
								onClick={() =>
									setMobileMenuOpen(!mobileMenuOpen)
								}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M4 6h16M4 12h8m-8 6h16"
									/>
								</svg>
							</button>
							<a className="btn btn-ghost normal-case text-xl">
								Launcher
							</a>
						</div>
						<div className="hidden md:flex flex-row gap-2">
							<TopNavItem label="Apps" path="/apps" />
						</div>
						<div className="navbar-end">
							<UserMenu />
						</div>
					</div>
					<Drawer
						open={mobileMenuOpen}
						onClickOverlay={() =>
							setMobileMenuOpen(!mobileMenuOpen)
						}
						side={
							<div className="w-80 h-full bg-neutral">
								<NavMenu />
							</div>
						}
					/>
				</header>
				<main className="w-full min-h-screen">{props.children}</main>
			</div>
		</Theme>
	);
};

const TopNavItem = (props) => {
	const location = useLocation();
	const isActive =
		location.pathname.split("/")[1] == props.path.replace("/", "")
			? true
			: false;
	return (
		<Link
			to={props.path}
			className={
				"rounded-lg pt-2 pb-2 pl-4 pr-4 hover:bg-black hover:bg-opacity-10 focus:bg-black focus:bg-opacity-40 font-bold" +
				(isActive === true ? " bg-black bg-opacity-40" : "")
			}
		>
			{props.label}
		</Link>
	);
};

TopNavItem.propTypes = {
	label: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
};

const NavMenu = () => {
	const menuItems = [
		{
			label: "Library",
			children: [
				{ label: "All", path: "/library/all" },
				{ label: "Shows", path: "/library/shows" },
				{ label: "Movies", path: "/library/movies" },
			],
		},
		{
			label: "Discover",
			children: [{ label: "Search", path: "/discover/search" }],
		},
		{
			label: "Manage",
			children: [
				{ label: "Requests", path: "/manage/requests" },
				{ label: "Queue", path: "/manage/queue" },
			],
		},
		{
			label: "Settings",
			children: [
				{ label: "Parrot", path: "/settings/parrot" },
				{ label: "Services", path: "/settings/services" },
				{ label: "Users", path: "/manage/users" },
			],
		},
	];

	return (
		<Menu>
			{menuItems.map((item, index) => {
				return (
					<Menu.Item key={index}>
						<Menu.Details
							label={item.label}
							open={true}
							className="font-bold"
						>
							{item.children.map((child, index) => {
								return (
									<Menu.Item
										key={index}
										className="font-normal"
									>
										<Link to={child.path}>
											{child.label}
										</Link>
									</Menu.Item>
								);
							})}
						</Menu.Details>
					</Menu.Item>
				);
			})}
		</Menu>
	);
};

const UserMenu = () => {
	const [userProfile, setUserProfile] = React.useState();
	const [userProfilePhoto, setUserProfilePhoto] = React.useState();

	const { instance } = useMsal();

	React.useEffect(() => {
		msGraphApi
			.getUserProfile()
			.then((res) => setUserProfile(res))
			.catch((err) => console.error("Failed to get user profile", err));

		msGraphApi
			.getUserProfilePhoto()
			.then((res) => setUserProfilePhoto(res))
			.catch((err) =>
				console.error("Failed to get user profile photo", err)
			);
	}, []);

	if (userProfile) {
		return (
			<Dropdown horizontal="left" vertical="bottom">
				<Dropdown.Toggle button={userProfilePhoto ? false : true}>
					{userProfilePhoto ? (
						<Avatar
							src={window.URL.createObjectURL(userProfilePhoto)}
						/>
					) : (
						<CiUser />
					)}
				</Dropdown.Toggle>
				<Dropdown.Menu className="card card-compact w-64 p-2 shadow bg-neutral text-neutral-content m-1">
					<Card.Body>
						<Card.Title tag={"h3"}>
							Hey {userProfile.givenName}!
						</Card.Title>
						<p>{userProfile.userPrincipalName}</p>
						<Button
							endIcon={<CiLogout />}
							variant="outline"
							onClick={() => instance.logoutRedirect()}
						>
							Sign out
						</Button>
					</Card.Body>
				</Dropdown.Menu>
			</Dropdown>
		);
	} else {
		return <>dsss</>;
	}
};

export default AppLayout;

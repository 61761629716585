import { msalInstance } from "../index";
import axios from "axios";

const get = ({ endpoint, scopes, options }) => {
	return new Promise((resolve, reject) => {
		const account = msalInstance.getActiveAccount();
		if (!account) {
			reject({ error: "No active account" });
		}

		msalInstance
			.acquireTokenSilent({
				scopes: scopes
					? scopes
					: ["https://graph.microsoft.com/.default"],
				account,
			})
			.then((tokenResponse) => {
				const accessToken = tokenResponse.accessToken;

				return axios
					.get(`https://graph.microsoft.com/${endpoint}`, {
						headers: { Authorization: `Bearer ${accessToken}` },
						...options,
					})
					.then((res) => {
						if (res.data) {
							resolve(res.data);
						} else {
							resolve(res);
						}
					})
					.catch((err) => {
						reject({
							error: "Request to Microsoft Graph API failed",
							trace: err,
						});
					});
			})
			.catch((err) => {
				reject({
					error: "Microsoft Graph authentication request failed",
					trace: err,
				});
			});
	});
};

export default {
	get,
	getUserProfile: () => {
		return new Promise((resolve, reject) => {
			return get({
				endpoint:
					"v1.0/me?$select=id,displayName,givenName,surname,userPrincipalName",
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	getUserProfilePhoto: () => {
		return new Promise((resolve, reject) => {
			return get({
				endpoint: "v1.0/me/photo/$value",
				options: { responseType: "ArrayBuffer" },
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
};

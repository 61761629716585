import React from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";

// MSAL imports
import {
	MsalProvider,
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from "@azure/msal-react";
import { CustomMsalNavigationClient } from "./utils/CustomMsalNavigationClient";

export default ({ pca }) => {
	const navigate = useNavigate();
	const msalNavigationClient = new CustomMsalNavigationClient(navigate);
	pca.setNavigationClient(msalNavigationClient);

	return (
		<MsalProvider instance={pca}>
			<AuthenticatedTemplate>
				<AuthenticatedApp />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<UnauthenticatedApp />
			</UnauthenticatedTemplate>
		</MsalProvider>
	);
};

const AuthenticatedApp = () => {
	return (
		<AppLayout>
			<span>Authenticated!</span>
		</AppLayout>
	);
};

const UnauthenticatedApp = () => {
	const { instance } = useMsal();

	React.useEffect(() => {
		instance.loginRedirect();
	}, []);

	return <span>Redirecting to SSO provider...</span>;
};
